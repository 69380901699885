.homepage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #FFFFFF;
  color: #212529;
  text-align: center;
  overflow: auto;
}

header {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 4em;
}

.icons-container {
  display: flex;
  justify-content: center;
  gap: 4em;
}

.icons-container a {
  text-decoration: none;
  color: #212529;
  text-align: center;
}

.inverted-icon {
  filter: invert(1);
  width: 100px; /* Ajustez la taille selon vos besoins */
  height: 100px; /* Ajustez la taille selon vos besoins */
}

@media (max-width: 1280px) {
  .inverted-icon {
    width: 70px; /* Réduit la taille de 30% */
    height: 70px; /* Réduit la taille de 30% */
  }
}

.icons-container p {
  margin-top: 1em;
  font-size: 1.5em;
}

/* Applique le même poids de police que @SideBar.css et une couleur noire aux textes au survol */
.homepage-container a {
  color: #212529;
  text-decoration: none;
  font-weight: bold; /* Même poids de police que @SideBar.css */
  transition: color 0.3s;
}

.homepage-container a:hover {
  color: black; /* Couleur noire au survol */
}