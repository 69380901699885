.hit-name {
    margin-top: 0;
    margin-bottom: 0;
}

a.hit-link {
    color: #88BDED;
    text-decoration: none;
}

a.hit-link:hover {
    color: #88BDED;
}

.hit-keyword,
.hit-topic,
.hit-data-source,
.hit-department {
    display: flex;
    gap: 10px;
}

.hit-keyword-icon {
    align-items:flex-start;
}

.hit-topic,
.hit-data-source,
.hit-department {
    align-items: center;
    font-weight: bold;
}

.keyword-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.keyword-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 8px;
    gap: 10px;
    background: #F3F2F1;
    border-radius: 20px;
    white-space: nowrap;
}

.keyword-item mark {
    background-color: #C5E4F8;
}

.hit-description mark {
    background-color: #C5E4F8;
}

.ais-Highlight mark {
    background-color: #C5E4F8;
    color: inherit;
}

.hit-details {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 10px;
}

.hit-separator {
    margin: 10px 0;
    opacity: 0.5;
}

.hit-title {
  position: relative;
  color: #88BDED;
  font-weight: bold;
  display: inline-block;
  padding-top: 10px;
  margin-bottom: 20px;
}

.hit-title::after {
  content: '';
  display: block;
  width: 50px;
  height: 2px;
  background-color: #88BDED;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.hit-item{
    padding-right: 40px;
}
