.inputHolderContainer {
    position: relative;
}

.input-placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;
}

.placeholderText {
}

.placeholderText-subtitle {
    color: #828282;
}



