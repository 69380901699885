  .searchBox {
    width: 70%;
  }

  .searchBox input {
    border-radius: 30px;
    padding: 20px;
    padding-left: 50px;
    border: 1px solid #E5E7EB;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 8px rgba(0, 0, 0, 0.08);

  }

  .searchBox button {
    margin-left: 15px;
  }

  .clearRefinementsButton {
    color: #343A33;
    border-radius: 20px;
    padding: 10px;
    background-color: #B9FF66;
    padding: 15px;
  }

  .clearRefinementsButton:not([disabled]):hover {
    box-shadow: 0px 5px 0px 0px #191A23;
    background-color: #B9FF66;
  }

  .clearRefinementsButton:disabled {
    background-color: #F3F2F1;
  }


  .ais-RefinementList-label {
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
  }

  .ais-RefinementList-checkbox {
    margin-right: 10px;
  }

  .ais-RefinementList-labelText {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    white-space: nowrap;
  }

  .ais-InfiniteHits-item {
    padding-top: 0 !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
  }

  .ais-RefinementList-count {
    margin-left: 10px;
  }

  [class^=ais-] {
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }

  .searchResult {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  .ais-InfiniteHits-item {
    width: 100% !important;
  }

  .hit-categories {
    font-style: italic;
  }

  .sideFilters {
    flex-basis: 30%;
    padding: 20px;
    margin-right: 20px;
    box-shadow: 0px 18.95px 47.38px 0px #7773AA1A;
  }

  .searchPanel {
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-left: 20px;
    width: 70%;
  }

  .searchContainer {
    display: flex;
    min-height: 100%;
  }


  @media (max-width: 1024px) {
    .sideFilters {
      width: 100%;
      padding-right: 0;
    }

    .searchPanel {
      width: 100%;
    }

    .searchContainer {
      display: block;
    }
  }

  .showMoreButton {
    background-color: #B9FF66 !important;
    border-radius: 20px;
    padding: 10px;
    color: black;
    margin-bottom: 10px;
  }

  .showMoreButton:hover {
    background-color: #B9FF66;
    box-shadow: 0px 5px 0px 0px #191A23;

  }

  .filtersHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  /* .ais-InfiniteHits {
    margin-top: 20px !important;
  } */

  .ais-InfiniteHits-list {
    margin-left: 0;
    margin-top: 0;
  }

  .searchBox {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .placeholderText {
    padding-left: 60px;
    padding-right: 20px;
  }

  .searchBoxWithStyledPlaceholder {
    width: 70%;
  }

  .didacticiel-icon {
    cursor: pointer;
    padding: 10px;
    border-radius: 30px;
    /* gray/200 */
    border: 1px solid #E5E7EB;
    /* depth 4 */
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 8px rgba(0, 0, 0, 0.08);
  }

  .customStats {
    font-style: italic;
    margin-bottom: 20px;
  }

  .searchBoxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }

  .react-joyride__tooltip p {
    text-align: left;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .react-joyride__tooltip h3 {
    font-family: 'Inter', sans-serif;
    color: #B9FF66;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding: 0px;
    margin: 0px;
  }

  .react-joyride__tooltip hr {
    border-top: 1px solid #828282;
  }
  