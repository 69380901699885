.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  color: #333;
}

.filter-container {
}

.filter-header-content {
  display: flex;
  align-items: center;
}

.filter-icon {
    margin-right: 10px;
}

.filter-title {
  font-weight: bold;
}

.chevron.down {
  transform: rotate(180deg);
}

.filter-content {
    
}

.ais-RefinementList-count {
   margin: 1px;
}
