.content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.themes-container, .departments-container {
  flex: 1 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.departments-container {
  border-left: 1px solid #ccc; /* Ajouté pour délimitation */
}

@media (max-width: 1280px) {
  .departments-container {
    flex: 1 1 100%;
    border-left: none; /* Supprime la délimitation en mode mobile */
  }
}

.departments-header, .themes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left h2 {
  margin-left: 10px;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

@media (max-width: 1920px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1280px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.card {
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  transition: transform 0.2s;
  display: flex;
  height: 50px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  color: black; /* Couleur de police de la sidebar */
  font-family: inherit; /* Applique la même police que la sidebar */
  text-decoration: none; /* Enlève le soulignement */
  box-shadow: 0px 1.6px 8px 0px #00000014, 0px 0.3px 0.9px 0px #0000001A; /* Ajouté pour correspondre aux spécifications Figma */
  border-radius: 9px;
}

.card:hover {
  background-color: #B9FF66; /* Couleur de police au survol de la sidebar */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
}

.card a:visited {
  color: black; /* Garde la couleur de police même si déjà visité */
}

.switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px; /* Ajouté pour espacer le switch du contenu */
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ccc; /* Ne change pas de couleur */
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.switch-label {
  font-size: 14px;
}

.title h1 {
  text-align: center;
}

.title-underline {
  width: 40px; /* Ajustez la largeur selon vos besoins */
  border: 0;
  border-top: 2px solid #333;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
  margin-bottom: 20px;
}

.margin-left {
  margin-left: 10px; /* Ajustez la valeur selon vos besoins */
}